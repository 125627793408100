import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const LotusLandingPage = () => (
  <PhysicianLandingPage
    physician="Milan Chavarkar DNP, RN"
    institution="Lotus Integrative Health and Nursing"
    referralCode="LOTUS"
    physicianURL="https://www.lotusintegrativehealth.com/about"
  />
)

export default LotusLandingPage
